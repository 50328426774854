import React, { useState, useEffect, useRef } from "react";
import {AnswerChat, askQuestionStreamAPI, read} from '../Data/ChatGPT';
import { Button, Drawer, IconButton, ListItem, Paper, TextField } from "@material-ui/core";
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

import './GPT.scss';
import { fn, randomString } from "../Functions";
import { ChevronRight } from "@material-ui/icons";
import EditableFileField from "../Component/EditableFileField";
import { flushSync } from "react-dom";

const tryJsonParse = (str, defaultValue={}) => {
    try{
        return JSON.parse(str);
    }catch(e){
        return defaultValue;
    }
}

const GPT = () => {

    useEffect(()=>{
        document.title = "Anthropic GPT";
    },[]);

    /**
     * @type {[
     *   ({role: 'system'|'user'|'assistant', content: string} | {role: 'user', content: ({type: 'text', text: string}|{type: 'image', source: string})[]})[],
     *   React.Dispatch<SetStateAction<{role: 'system'|'user'|'assistant', content: string}[]>>
     * ]}
     */
    const [chat, setChat] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [content, setContent] = useState('');
    const [needToRetry, setNeedToRetry] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const elapsedTimer = useRef(null);

    const [lastChatMessage, setLastChatMessage] = useState(null);

    const [chatId,setChatId] = useState(randomString(32));

    let savedHistory = tryJsonParse(localStorage.getItem('chatHistory'), []) ?? [];
    for(let chat of savedHistory){
        for (let message of chat.data){
            if (message.content && Array.isArray(message.content)){
                for (let content of message.content){
                    if (content.type === 'string'){
                        content.type = 'text';
                        content.text = content.content;
                    }
                }
            }
        }
    }
    const [chatHistory, setChatHistory] = useState(savedHistory);



    useEffect(()=>{
        
        const timer = setTimeout(()=>{
            setChatHistory(chatHistory => {
                if (chat.filter(row => row.role === 'user').length === 0){
                    return chatHistory;
                }

                const firstContent = chat?.filter(row => row.role === 'user')[0].content;
                let title = "빈 대화";
                if (Array.isArray(firstContent)) {
                    title = firstContent.filter(row => row.type === 'text')[0].text.substring(0, 50);
                } else if (typeof firstContent === 'string'){
                    title = firstContent.substring(0, 50);
                }

                const newChatHistory = [
                    ...(chatHistory?.filter(chat => chat.chatId !== chatId) || []),
                    {
                        chatId,
                        title,
                        data: chat ?? [],
                        created_datetime: new Date().toLocaleString()
                    }
                ];

                localStorage.setItem('chatHistory', JSON.stringify(newChatHistory));
                return newChatHistory
            });
        }, 1000);
        return ()=>clearTimeout(timer);

    },[chat, chatId])

    return (<div style={{
        width: "calc(100% - 40px)", maxWidth: 800, minHeight: '100vh',
        margin: "0 auto", padding: 20, boxSizing: "border-box",
        backgroundColor:'rgba(245,245,245,1)', boxShadow: "0 0 10px rgba(0,0,0,0.1)"
    }}>
        {<Drawer open={isDrawerOpen} onClose={()=>setIsDrawerOpen(false)} anchor={"left"} style={{maxWidth: '80%'}}>
            {chatHistory?.map((chat, idx) =>
                <ListItem key={idx} button style={{whiteSpace: 'nowrap'}} onClick={()=>{
                    setChat(chat.data);
                    setChatId(chat.chatId);
                    setIsDrawerOpen(false);
                }}>
                    {`${chat.title}(${chat.created_datetime})`}
                </ListItem>
            )}
        </Drawer>}
        {!isDrawerOpen && <IconButton onClick={()=>setIsDrawerOpen(true)} style={{position: 'fixed', left: 10, top: 10, zIndex: 1000}}><ChevronRight/></IconButton>}
        {chat.map((message, idx) =>
        <Paper
            variant="outlined"
            style={{margin: 10, wordBreak:'keep-all', padding: 10}} key={idx}>
            <div style={{fontWeight: 'bold'}}>
                {message.role}
            </div>
            <div>
            {Array.isArray(message.content)
            ?message.content.map((content, idx) => content.type === 'text'
                ?<Markdown key={`content_${idx}`} remarkPlugins={[gfm]}>{content.content}</Markdown>
                :<img key={`content_${idx}`} src={content.source} alt={content.source} style={{maxWidth: '100%', maxHeight: '100%'}}/>)
            :<Markdown remarkPlugins={[gfm]}>{message.content}</Markdown>}
            </div>
        </Paper>)}
        {needToRetry > 0 && <Paper
            variant="outlined"
            style={{margin: 10, wordBreak:'keep-all', padding: 10}}>
            <div style={{fontWeight: 'bold'}}>
                {"assistant"}
            </div>
            <Markdown remarkPlugins={[gfm]}>{lastChatMessage}</Markdown>
        </Paper>}
        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems: 'center', gap: 10}}>
            <TextField fullWidth multiline
                value={Array.isArray(content)?content.filter(row => row.type ==='text')[0].text:content} onChange={(e) => {
                    if (Array.isArray(content)){
                        setContent(content => [
                            ...content.filter(row => row.type !== 'text'),
                            {type: 'text', content: e.target.value}
                        ]);
                    } else {
                        setContent(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if(e.key === 'Enter' && e.ctrlKey && needToRetry === 0){
                        e.target.blur();
                        document.getElementById('ask').click();
                    }
                }}
            />
            <EditableFileField
                style={{maxHeight: 200, maxWidth: 200}}
                isEditing={true}
                field={"image"}
                defaultValue={Array.isArray(content)?content.filter(row => row.type ==='image')[0].source:null}
                update={({image})=>{
                    if (!image){
                        setContent(content => content.filter(row => row.type === 'text')[0]?.text ?? "");
                        return;
                    }

                    if (Array.isArray(content)){
                        setContent(content => [
                            ...content.filter(row => row.type === 'text'),
                            {type: 'image', source: image}
                        ]);
                    } else {
                        setContent(content => [
                            {type: 'image', source: image},
                            {type: 'text', text: content}
                        ])
                    }
                }}
            />
        </div>
        {needToRetry === 0
        ?<Button id={"ask"} variant="contained" color="primary" style={{margin: '10px 10px', width: 'calc(100% - 20px)'}}
            onClick={()=>{
                setElapsedTime(0);
                elapsedTimer.current = setInterval(()=>{
                    setElapsedTime(elapsedTime => elapsedTime + 100);
                }, 100);
                setNeedToRetry(1);
                const newChat = [
                    ...chat,
                    {role: 'user', content: content || "(아무말 하지 않음)"}
                ]
                const chatWithResp = [...newChat, {role: 'assistant', content: ""}];  
                setChat(newChat);
                setContent("");

                /**
                 * 
                 * @param {string} chunk 
                 */
                const CB = async (chunk) => {
                    chatWithResp[chatWithResp.length - 1].content += chunk;
                    setLastChatMessage(chatWithResp[chatWithResp.length - 1].content);
                }

                (async()=>{
                    await askQuestionStreamAPI({chat: newChat, CB});
                    clearTimeout(elapsedTimer.current);
                    setChat(chatWithResp.map(row => ({...row})));
                    setLastChatMessage(null);
                    setNeedToRetry(0);
                })();



            }}
        >{`물어보기 ${elapsedTime>0?`(지난 답변에서 ${`${elapsedTime / 1000}`.substring(0,4)} 초 소요)`:''}`}</Button>
        :`${`${elapsedTime / 1000}`.substring(0,4)}초 째 기다리는 중...`}

        {(content === "" && chat.length === 0) && <Button variant="contained" style={{margin: '10px 10px', width: 'calc(100% - 20px)'}} href={"/cot"} onClick={fn.gotoByAnchor}>{"Chain of thought 이용하기"}</Button>}
    </div>);
}

export default GPT;